<template>
  <div class="convert">
    <div id="Content">
      <div class="content-inner">
        <div class="row">
          <div class="col flex center">
            <h1>Convert</h1>
          </div>
          <div class="col full flex center">
            <FormulateInput
              v-model="json"
              type="textarea"
              style="height:100px"
            ></FormulateInput>
          </div>
          <div class="col full flex center">
            <FormulateInput
              type="button"
              name="Convert"
              @click="convert"
            ></FormulateInput>
          </div>

          <div class="col full flex center" v-if="result">
            <FormulateInput
              v-model="result"
              type="textarea"
              style="height:100px"
            ></FormulateInput>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      json: "",
      result: ""
    };
  },
  methods: {
    convert() {
      let jsonData = `${this.json}`;
      var header_content = jsonData.indexOf('"header_content');
      var footer_content = jsonData.indexOf('"meta_title');

      var data = jsonData.slice(header_content, footer_content);
      var newData = jsonData.split(data);

      this.result = newData[0] + newData[1];

      console.log(newData[0] + newData[1]);
    }
  }
};
</script>

<style></style>
